<template>
    <div class="catalog">
        <div class="catalog-options">
            <div class="option" @click="modle = 'Departments'">
                <i class="material-icons">supervised_user_circle</i>
                <p>מחלקות</p>
            </div>
            <div class="option" @click="modle = 'Products'">
                <i class="material-icons">supervised_user_circle</i>
                <p>פריטים</p>
            </div>
            <div class="option" @click="modle = 'ProductsToAttend'">
                <i class="material-icons">supervised_user_circle</i>
                <p>פריטים חריגים</p>
            </div>
            <div class="option" @click="modle = 'ItemFrames'">
                <i class="material-icons">supervised_user_circle</i>
                <p>מסגרות</p>
            </div>
            <div class="option" @click="modle = 'Prices'">
                <i class="material-icons">supervised_user_circle</i>
                <p>צפייה במחירים</p>
            </div>
            <div class="option" @click="modle = 'Kadam'">
                <i class="material-icons">sports_esports</i>
                <p>קידום מכירות</p>
            </div>
            <div class="option">
                <i class="material-icons">rule</i>
                <p>משימות</p>
            </div>
            <div class="option" @click="modle = 'Flayers'">
                <i class="material-icons">picture_as_pdf</i>
                <p>פלאיירים</p>
            </div>
        </div>
        <div v-if="modle" class="catalog-panel">
            <component :is="modle"/>
        </div>  
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { defineAsyncComponent } from '@vue/runtime-core';
export default {
components:{
    Departments:defineAsyncComponent(()=>import('../../components/Admin/Catalog/Departments.vue')),
    ProductsToAttend:defineAsyncComponent(()=>import('../../components/Admin/Catalog/ProductsToAttend.vue')),
    ItemFrames:defineAsyncComponent(()=>import('../../components/Admin/Catalog/ItemFrames.vue')),
    Kadam:defineAsyncComponent(()=>import('../../components/Admin/Catalog/Kadam.vue')),
    Prices:defineAsyncComponent(()=>import('../../components/Admin/Catalog/Prices.vue')),
    Products:defineAsyncComponent(()=>import('../../components/Admin/Catalog/Products.vue')),
    Flayers:defineAsyncComponent(()=>import('../../components/Admin/Catalog/Flayers.vue'))
},
setup(){
    const modle = ref(null)
   
    return{ 
        modle,
    }
}
}
</script>

<style scoped>
.catalog{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 350px calc(100% - 350px);
    overflow-y: auto;
}
.catalog-options{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 50px;
    gap: 8px;
    overflow-y: auto;
}
.option{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 26px;
    background: var(--secondary);
    padding: 0 5px 0 0;
    border-radius: 5px;
    cursor: pointer;
}
.option:hover p{
    margin-right:25px;
    color: var(--yellow);
}
.option i {
    margin-left: 25px;
    color: var(--light);
    font-size: 33px;
}
.option p{
    color:var(--light);
    transition: 0.25s;
}
.catalog-panel{
    width: 100%;
    height: 100%;
    padding: 0 5px 0 5px;
}

@media only screen and (max-width: 660px) {
    .catalog{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 180px 600px;
        grid-template-columns: 100%;
    }
    .catalog-panel{
        height: 100%;
        padding: 0 0 0 0;
    }
    ::-webkit-scrollbar{
        width: 0;
    }
}
</style>